<template>
  <div class="ant-upload-credit">
    <h2 class="ant-upload-h2-credit">
      <slot></slot>
    </h2>
  </div>
</template>
<style lang="less">
.ant-upload-h2-credit {

  color: #8F959E;
}
</style>