<template>
  <div class="common-upload-container flex justify-center items-center">
    <div class="upload-position-container">
      <div class="upload-box upload-single flex justify-center items-center" v-authenticate:PHOTO_STORYTELLING>
        <commonUpload :fileList="fileList" :setFileList="setFileList" :getFileListLength="getFileListLength"
          placeHolder="Upload an image to start" :multiple="false" :customRequest="customRequest"
          accept=".JPG,.jpg,.png,.PNG,.webp,.WEBP,.jpeg,.JPEG" :limitNum="10" :iconUrl="iconUploadSingle" :iconStyle="{
            width: '46px',
            height: '58px',
            'margin-bottom': '25px',
          }" />
      </div>
      <div class="image-box-background" @mouseenter="handleShowImageList" @mouseleave="handleHiddenImageList">
        <div class="inner-image-box">
          <p class="tooltip-title">No image? Try one of these</p>
          <div class="example-images-box">
            <div class="example-image-icon-box" v-for="item in imagesList" :key="item.id"
              v-authenticate:PHOTO_STORYTELLING="{ ok: () => handleSelectImage(item) }">
              <div class="mask-item"></div>
              <img :src="item.iconSrc" class="example-image-icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import loadImage from "blueimp-load-image";
import { ref, defineEmits, onMounted, onUnmounted } from "vue";
import commonUpload from "@/components/custom-upload/index.vue";
import dataUtils from "@/utils/util.js";
import iconUploadSingle from "@/assets/upload-images/icon_upload_single.svg";
import removeIcon1 from "@/assets/example-images/remove_icon_1.webp";
import removeIcon2 from "@/assets/example-images/remove_icon_2.webp";
import removeIcon3 from "@/assets/example-images/remove_icon_3.webp";
import removeSrc1 from "@/assets/example-images/remove_src_1.webp";
import removeSrc2 from "@/assets/example-images/remove_src_2.webp";
import removeSrc3 from "@/assets/example-images/remove_src_3.webp";
import {
  MessageLoading,
  destroy,
} from "@/components/boolv-ui/Message/index.js";

import { useViewStore, useUserStore } from '@/store/index.js'
const user = useUserStore();

const viewStore = useViewStore()
const uploadFileList = ref([]);
const uploadCount = ref(0);
const isUploading = ref(false);
const formateImageList = ref([]);
const isShowImageList = ref(false);
const currentSelectItem = ref(null);
const selectExampleImage = ref(false);
const allFileListLength = ref(0);
const imagesList = ref([
  {
    id: 1,
    iconSrc: removeIcon1,
    src: removeSrc1,
  },
  {
    id: 2,
    iconSrc: removeIcon2,
    src: removeSrc2,
  },
  {
    id: 3,
    iconSrc: removeIcon3,
    src: removeSrc3,
  },
]);
const preload = () => {
  imagesList.value.forEach(item => {
    const img = new Image();
    img.src = item.iconSrc;
  })
}
onMounted(() => {
  // viewStore.setMinViewWidth('1400px')
  preload();
})
const emits = defineEmits(["uploadComplete"]);

const customRequest = () => {
};

const handleSelectImage = (item) => {
  destroy()
  MessageLoading("Uploading, it will take a while");
  sessionStorage.setItem("sourceImageId", JSON.stringify([`default_image_${item.id}`]));
  selectExampleImage.value = true;
  currentSelectItem.value = item;
  uploadFileList.value = [item.src];
  formateImageList.value = formateImageToImageData([item.src]);
};

const setFileList = (fileList = []) => {
  destroy()
  MessageLoading("Uploading, it will take a while");
  const fileArray = fileList.map((file) => file.originFileObj);
  uploadFileList.value = fileArray;
  formateImageList.value = formateImageToImageData(fileArray);
};

const formateImageToImageData = async (fileList) => {


  const uploadImageData = [];
  // await user.userSubscription();
  uploadCount.value = 0;
  isUploading.value = true;
  fileList.forEach((file, index) => {
    loadImage(
      file,
      async (img) => {
        if (img.type === "error") {
          console.log("error");
        } else {
          const tempCtx = dataUtils.createCanvasCtx(img);
          tempCtx.drawImage(img, 0, 0);
          const imageData = tempCtx.getImageData(0, 0, img.width, img.height);
          uploadImageData.push({
            id: index + 1,
            img,
            file: file,
            operationalData: tempCtx.getImageData(0, 0, img.width, img.height),
            originImageData: imageData, //不可更改
          });

          uploadCount.value += 1;
          if (uploadCount.value === uploadFileList.value.length) {
            isUploading.value = false;
            emits("setFormateImageList", uploadImageData);
            destroy()
            emits("uploadComplete", true);

          }
        }
      },
      {
        cover: true,
        crop: true,
        canvas: true,
        crossOrigin: "Anonymous",
      }
    );
  });
  return uploadImageData;
};

const handleShowImageList = () => {
  isShowImageList.value = true;
};

const handleHiddenImageList = () => {
  isShowImageList.value = false;
};



const getFileListLength = (value) => {
  allFileListLength.value = value;
};

onUnmounted(() => {
  destroy()
})
</script>

<style lang="less" scoped>
.common-upload-container {
  min-height: calc(100vh - 60px);
  padding: 60px 0;
  min-width: 1400px;

  .tooltip-card {
    width: 394px;

    left: "280px";
  }

  :global(.ant-tooltip-content) {
    width: 394px;
    height: 100%;
  }

  :global(.ant-tooltip-inner) {
    width: 394px;
    height: 100%;
    color: #8f959e;
  }
}

.upload-box {
  width: 588px;
  height: 196px;
  background: #ffffff;
  border: 1px dashed #d0d0d0;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: #f8f5ff;
  }
}

.upload-single {
  margin-right: 34px;
}

.example-images-container {
  width: 394px;

  background-color: green;
}

.example-image-icon {
  width: 110px;
  height: 110px;
  border-radius: 4px;
}

.example-images-box {
  display: flex;
  justify-content: space-between;
  padding: 22px 20px;
}

.image-box-background {
  width: 588px;
  height: 230px;
  padding-top: 14px;
}

.inner-image-box {
  width: 588px;
  padding: 0 95px;
}

.upload-position-container {
  position: relative;
}

.example-image-icon-box {
  cursor: pointer;
  position: relative;
  background-color: #F6F6F6;
  width: 110px;
  height: 110px;
  border-radius: 4px
}

.mask-item {
  position: absolute;
  width: 110px;
  height: 110px;
  border-radius: 4px;
  opacity: 0;
  background-color: #000;
  transition: all .3s;
  cursor: pointer;

  &:hover {
    opacity: 0.3;
  }
}
</style>
