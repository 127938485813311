import { _post } from "@/utils/request.js";
const preApi = process.env.NODE_ENV !== "production" ? "/api" : process.env.VUE_APP_API;
const URL = {
  smartResponseGenerator: preApi + "/ai/smart_response_generator",
  blogRewriter: preApi + "/ai/blog_rewriter",
  photoStoryTeller: preApi + "/ai/photo_story_teller",
};

const getSmartResponseGenerator = async (data, config) => _post(URL.smartResponseGenerator, data, config);
const getBlogRewriter = async (data, config) => _post(URL.blogRewriter, data, config);
const getPhotoStoryTeller = async (data, config) => _post(URL.photoStoryTeller, data, config);
export { getSmartResponseGenerator, getBlogRewriter, getPhotoStoryTeller };
