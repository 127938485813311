<template>
  <div class="upload-content-container">
    <slot> </slot>
  </div>
</template>
<script setup>
</script>
<style lang="less">
.upload-content-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>