
<template>
  <div>
    <!-- 人像抠图 -->
    <photo-story-teller v-if="uploadComplete" :imageList="uploadImageList" :uploadSuccessImages="uploadSuccessImages"
      @back="handleBack" />
    <bg-remove-upload @setFormateImageList="handleSetFormateImageList" @isBatch="setIsBatch"
      @uploadComplete="handleUploadComplete" v-else />
  </div>
</template>
<script setup>
import bgRemoveUpload from './image-upload.vue'
import PhotoStoryTeller from '@/components/models/photo-story-teller/index.vue'
import { ref } from 'vue'
import { eventTracking } from "@/utils/eventTracking.js";
import { uploadImageSingle } from "@/api/upload/upload.js";
import dataUtils from "@/utils/util.js";
var md5 = require("md5");

const uploadComplete = ref(false)
const uploadImageList = ref([])
const isBatch = ref(false)
const uploadSuccessImages = ref([])
const currentUploadFileLength = ref(0)

const uploadImageFn = (file) => {
  let params = {
    key: `photo-story-teller-${md5(Date.now())}`,
    file: file,
  };
  uploadImageSingle(params)
    .then((res) => {
      const result = res.data || {};
      // 存储上传结果
      uploadSuccessImages.value.push({
        id: res.data.key,
        url: result.url
      })

      currentUploadFileLength.value++
    })
    .catch((err) => {
      console.log("err====", err);
    });
};

const handleSetFormateImageList = (imageList) => {

  uploadImageList.value = imageList
  imageList.forEach((image) => {
    const file = dataUtils.transformFile(
      image.img,
      `photo-story-teller-${md5(Date.now())}.png`
    );
    uploadImageFn(file);
  });
}

const handleBack = () => {
  uploadImageList.value = []
  uploadComplete.value = false
  uploadSuccessImages.value = []
}

const setIsBatch = (value) => {
  isBatch.value = value
}

const handleUploadComplete = (isComplete) => {
  eventTracking("booltool_page_upload", { tool_name: "storytelling", is_batch: false })
  uploadComplete.value = true
}
</script>

<style lang="less"></style>