<template>
  <div class="back-ground-remover-wrap">

    <div class="top-container">
      <div class="back-btn-container">
        <back-btn @back="handleBack" />
      </div>
    </div>
    <div class="image-show-container" :style="{ paddingTop: minClient ? '30px' : '49px' }">
      <div v-if="innerImageList && innerImageList.length">
        <div class="flex" v-for="(item, index) in innerImageList" :key="index">
          <div class="flex-left">
            <div class="result-container container">
              <div class="position-box">
                <div class="result-item-box">
                  <div class="image-box result-image-box"
                    :style="{ width: currentContainerSize.width, height: currentContainerSize.height }">
                    <image-board ref="resultBoardRef" :srcData="item.originImageData" :isModelDone="isModelDone"
                      :currentContainerSize="currentContainerSize.width" />
                  </div>
                </div>
                <!--  loading mask -->
                <div class="loading-mask-box" v-if="!uploadSuccessImages[index]"></div>
              </div>
            </div>
          </div>
          <div class="flex-right">
            <div class="add-container container">
              <div class="image-box  position-box border-box" :class="{ 'add-design-container': !showGenerateResult }"
                :style="{ width: currentContainerSize.width, height: currentContainerSize.height }">
                <div class="mask-box" v-if="!uploadSuccessImages[index]"
                  :style="{ width: currentContainerSize.width, height: currentContainerSize.height }"></div>
                <div class="button-box" v-if="!showGenerateResult">
                  <custom-btn :customStyle="customStyle" @click="(e) => handleGenerate(e, item)" :showPrefixIcon="true"
                    activeColor=" linear-gradient(289deg, #7646ff 18.5%, #9c7aff 99.95%)">
                    AI Generate
                  </custom-btn>
                </div>
                <div v-if="showGenerateResult && !textContent" class="ellipsis-box">
                  <div>
                    <dynamic-ellipsis radius="12px" color="#C4ADFF" activeColor="#C4ADFF" display="inline-block" />
                  </div>
                </div>
                <div class="text-container"
                  :style="{ opacity: showGenerateResult && textContent ? 1 : 0, width: currentContainerSize.width, height: currentContainerSize.height }">
                  <div class="text-box" id="text-box"></div>

                  <div class="icon-footer">
                    <div class="flex justify-between items-baseline">
                      <div class="flex items-baseline" :style="{ opacity: showGenerateResult && textContent ? 1 : 0 }">
                        <a-tooltip placement="bottom" arrowPointAtCenter overlayClassName="copy-tooltip">
                          <template #title>Copy</template>
                          <div class="icon-copy-box" @click="handleCopyText" v-clipboard:success="clipboardSuccessHandler"
                            v-clipboard:error="clipboardErrorHandler">
                            <img :src="iconCopy" class="icon-copy" />
                          </div>
                        </a-tooltip>
                        <div>
                          <common-rate @changeRate="handleReplaceBgChangeRate" :clearRate="clearRate" ref="rate"
                            :showText="false" width="48px" height="48px" />
                        </div>
                      </div>
                      <div class="result-count">{{ textContent && textContent.length }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <common-rate @changeRate="handleReplaceBgChangeRate" v-if="showGenerateResult && textContent"
                :clearRate="clearRate" ref="rate" class="common-rate-abs" /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>

import BackBtn from '@/components/boolv-ui/back-btn'
import DynamicEllipsis from "@/components/dynamic-ellipsis.vue";
import { ref, defineProps, watchEffect, watch, reactive, defineEmits, nextTick, onUnmounted, onMounted, computed } from "vue";
import CommonRate from "@/components/common-rate.vue";
import iconCopy from '@/assets/common-icons/icon_copy.svg'
import ImageBoard from '@/components/image-board';
import CustomBtn from "@/components/boolv-ui/custom-btn/index.vue";
import iconPro from '@/assets/common-icons/icon_pro.svg'
import { getPhotoStoryTeller } from '@/api/models/photo-story-teller.js'
import { typeWriterString } from '@/utils/typewriter.js'
import { authenticator } from '@/utils/authenticate.js'
import { eventTracking } from "@/utils/eventTracking.js";
import { Clipboard } from "v-clipboard"
import { storeToRefs } from 'pinia'
import {
  MessageError,
  MessageLoading,
  MessageSuccess,
  destroy,
} from "@/components/boolv-ui/Message/index.js";

import { useUserStore, useSideStore, useViewStore } from '@/store/index.js'
const user = useUserStore();
const sideStore = useSideStore()
const viewStore = useViewStore()
const { minClient } = storeToRefs(sideStore)

const emits = defineEmits(['back'])
const props = defineProps({
  imageList: Array,
  uploadSuccessImages: Array,
});

const innerImageList = ref([])
const showGenerateResult = ref(false);
const rate = ref(null)
const resultBoardRef = ref(null);
const currentReplaceBgSelectRate = ref({});
const clearRate = ref(false)
const isModelDone = ref(false)
const textContent = ref('')

const currentContainerSize = computed(() => {
  if (sideStore.minClient) {
    return {
      width: '560px',
      height: '560px'
    }
  } else {
    return {
      width: '560px',
      height: '560px'
    }
  }
})

const customStyle = reactive({
  width: '270px',
  height: '64px',
  background: 'linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%)',
  'border-radius': '60px'
})

onMounted(() => {
  MessageLoading("Processing, it will take a while", 0, "", null, false);
  // viewStore.setMinViewWidth('1400px')
})

watchEffect(async () => {
  if (props.imageList && props.imageList.length) {
    innerImageList.value = props.imageList
  }

  if (textContent.value) {
    await nextTick()
    const textBox = document.getElementById('text-box')
    typeWriterString(textBox, textContent.value)
  }

})

watch(() => props.uploadSuccessImages, () => {
  MessageSuccess("Processing completed");
}, {
  deep: true
})

const handleReplaceBgChangeRate = (item) => {
  currentReplaceBgSelectRate.value = item;
  const emoji = ['star_struck', 'face_blowing_a_kiss', 'thinking_face', 'face_vomiting'];
  eventTracking('booltool_page_feedback', { tool_name: 'storytelling', emoji_str: emoji[item.id - 1] });
};

const clearTask = (text = 'Error') => {
  showGenerateResult.value = false
  destroy()
  MessageError(text)
  setTimeout(() => {
    destroy()
  }, 1000)
}

const handleGenerate = async (e, item) => {
  showGenerateResult.value = true
  const params = {
    inputS3Uri: props.uploadSuccessImages && props.uploadSuccessImages[0].url
  }

  // MessageLoading("Processing, it will take a while", 0, "", null, false);
  getPhotoStoryTeller(params).then(async res => {
    destroy()
    const { code, data } = res
    if (code === 0) {
      eventTracking("booltool_page_download", { tool_name: "storytelling", is_batch: false, is_success: true })
      textContent.value = data.text || ''
    }
    await authenticator.refresh();
  })
    .catch((err) => {
      showGenerateResult.value = false

      if (err.code != 101003) {
        clearTask('Error')
      }
    })
}

const interruptRequest = () => {
  showGenerateResult.value = false
  destroy()
}
const handleBack = () => {
  emits('back')
}


const handleCopyText = async () => {
  await Clipboard.copy(textContent.value)
}

const clipboardSuccessHandler = () => {
  MessageSuccess('Copied to clipboard!')
}

const clipboardErrorHandler = () => {
  MessageError('copy failed!')
}

onUnmounted(() => {
  destroy()
  sideStore.showGlobalMask = false;
})
</script>

<style lang="less" scoped>
.back-ground-remover-wrap {
  min-height: calc(100vh - 60px);
  min-width: 1400px;
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.top-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.image-show-container {
  min-height: calc(100vh - 100px);
}

.flex-left {
  // width: 560px;
  padding: 0px 0px 61px 34px;
  margin-right: 60px;
}

.flex-left-title {
  color: #000000;
  padding-bottom: 15px;
  text-align: left;
}

.flex-right {
  padding: 0px 32px 37.63px 0px;

  .tip {
    color: #8F959E;
    margin-top: 14px;
  }
}

.backGroundRemovers-container {
  width: 100%;
  min-height: calc(100vh - 60px);
  display: grid;
}


.result-item-box {
  position: relative;
}

.result-container {
  width: 100%;
  height: 100%;
}

.add-container {
  margin-right: 0;
}

.tool-title {
  font-family: 'Inter', Arial;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: #000000;
}


.result-title {
  font-family: 'Inter', Arial;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 24px;
}

.position-box {
  position: relative;
  z-index: 1;

  :global(.ant-tooltip-inner) {
    border-radius: 4px;
  }

  .icon-check-original-box {
    position: absolute;
    right: 22px;
    top: 22px;
    cursor: pointer;
  }

  .icon-checkbox {
    position: absolute;
    top: 14px;
    right: 14px;
    cursor: pointer;
  }

  .buttons-container {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 9999;
    font-family: 'Inter', Arial;

    .ant-btn {
      width: 120px;
      height: 36px;
      border-radius: 200px;
      color: #fff;
      border: none;
    }

    .ant-btn-default {
      background: rgba(0, 0, 0, 0.8);
      opacity: 0.8;
    }

    .ant-btn-primary {
      opacity: 1;
      background: #875eff;
    }
  }

  .buttons-container-justify {
    display: flex;
    justify-content: center;
    padding: 0 80px 14px 80px;
  }

  .buttons-container-padding {
    padding: 0 154px 30px 154px;
  }


  .buttons-container-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 18px;
  }

  @keyframes fadenum {
    0% {
      opacity: 0;
      transform: scaleX(0.5);
    }

    100% {
      opacity: 1;
      transform: scaleX(1);
    }
  }
}

.image-box {
  // width: 560px;
  // height: 560px;
  position: relative;
}

.mask-box {
  position: absolute;
  // width: 560px;
  // height: 560px;
  border-radius: 4px;
  background-color: #F7F7F7;
  opacity: 0.5;
  z-index: 999;
}

.border-box {
  border: 1px solid #e0e0e0;
  // border: 1px solid #D0D0D0;
  // border-radius: 6px;
}

.result-image-box {
  position: relative;

  .canvas-box {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
  }
}

.draw-canvas {
  position: absolute;
  top: 0;
  left: 0;
}


.backend-selector {
  width: 200px;
}


.add-design-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.add-design-tip {
  font-family: 'Inter', Arial;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #969696;
  margin-top: 42px;

  P {
    margin-bottom: 0;
  }
}

.button-box {
  width: 100%;
  display: flex;
  justify-content: center;
  // margin-top: 42px;
  flex-direction: column;
  align-items: center;

}

.bg-item {
  width: 76px;
  height: 76px;
  background: #d9d9d9;
  border-radius: 4.14545px;
  cursor: pointer;
}

.back-btn-container {
  padding-top: 25px;
}

.btn-container {
  width: 100%;
  display: flex;
  padding: 16px 0 36px 0;
  justify-content: flex-end;
  align-items: center;
}

.btn-position {
  width: 100%;
  position: fixed;
  bottom: 68px;
  z-index: 99;
}

:global(.copy-tooltip .ant-tooltip-inner) {
  width: fit-content;
  background: #1f2329;
  border-radius: 4px;
  font-size: 12px;
  color: #ffffff;
}

:global(.copy-tooltip .ant-tooltip-content) {
  width: fit-content;
}

.select-box {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 42px;
}



.btn-suffixIcon {
  width: 40px;
  height: 24px;
  margin-left: 12px;
}

.icon-footer {
  height: 60px;
  position: absolute;
  bottom: 16px;
  width: 85%;

}

.icon-copy {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.icon-copy-box {
  width: 48px;
  height: 48px;
  border: 1px solid #D0D0D0;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 24px;

  &:hover {
    background-color: #EDEDED;
  }
}

.text-container {
  // display: flex;
  padding: 36px 36px 30px 36px;
  // justify-content: center;
  // flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;



  .text-box {
    // overflow-y: scroll;
    width: 100%;
    height: 80%;
    white-space: pre-wrap;
    text-align: left;
    font-size: 16px;

    :global(.ant-input) {
      border: none;
    }

    :global(.ant-input[disabled]) {
      background-color: #fff !important;
      color: #000;
    }

    :global(.ant-input-disabled) {
      background-color: #fff !important;
      color: #000;
    }

    textarea {
      resize: none
    }
  }
}

.ellipsis-box {
  display: flex;

  padding: 36px 36px 30px 36px;
}


.loading-mask-box {
  width: 100%;
  height: 100%;
  background-color: #F7F7F7;
  opacity: 0.5;
  position: absolute;
  left: 0;
  top: 0;
  text-align: right;
}
</style>
